<template>
  <div>
    <div class="d-flex justify-content-center pt-2 pb-5">
      <div class="col-lg-8 col-md-8 col-xs-6 widget-boxed mt-33 mt-0">
        <div class="widget-boxed-header">
          <h4>Profile Details</h4>
        </div>
        <div class="sidebar-widget author-widget2">
          <div class="author-box clearfix d-flex mt-2">
            <label for="upload">
              <img
                :src="
                  profilePic
                    ? profilePic
                    : require(`../assets/images/default-profile.webp`)
                "
                alt="author-image"
                class="author__img"
              />
              <input
                @change="addProfilePic($event)"
                type="file"
                id="upload"
                style="display: none"
              />
            </label>
            <div>
              <h4 class="author__title">{{ toCap(buyerSeller.name) }}</h4>
              <p class="author__meta">{{ getAddress() }}</p>
            </div>
          </div>
          <div>
            <ul class="author__contact" style="padding-left: 30px">
              <li class="d-flex mb-5 res-mb-4">
                <img
                  style="margin-right: 3%"
                  class="bedroomAdvance w-30"
                  :src="require('../assets/images/profile.webp')"
                />
                <input
                  style="height: 24px"
                  v-model="username"
                  readonly
                  class="input-style w-244"
                  type="text"
                  placeholder="Nickname"
                />
              </li>

              <li class="d-flex mb-5 res-mb-4">
                <img
                  style="margin-right: 3%"
                  class="bedroomAdvance w-30"
                  :src="require('../assets/images/lastname.webp')"
                />
                {{ toCap(buyerSeller.name) }}
              </li>
              <li class="dflexx mb-5 res-mb-4">
                <img
                  style="margin-right: 3%"
                  class="bedroomAdvance w-30"
                  :src="require('../assets/images/address.webp')"
                />
                <p>
                  <input
                    v-model="houseNo"
                    class="input-style w-244"
                    type="text"
                    placeholder="House/Flat Number"
                  />
                  <input
                    v-model="address"
                    class="input-style ml-3 res-remove-ml w-244"
                    type="text"
                    placeholder="Address Line 1"
                  />
                  <input
                    v-model="addressTwo"
                    class="input-style ml-3 res-remove-ml w-244"
                    type="text"
                    placeholder="Address Line 2"
                  />
                </p>

                <div class="res-remove-mobile" style="margin-left: 64px">
                  <input
                    v-model="city"
                    class="input-style w-244"
                    type="text"
                    placeholder="City"
                  />
                  <input
                    v-model="postCode"
                    class="input-style ml-3 res-remove-ml w-244"
                    type="text"
                    placeholder="Postcode"
                  />
                  <input
                    v-model="county"
                    class="input-style ml-3 res-remove-ml w-244"
                    type="text"
                    placeholder="County"
                  />
                  <br />
                  <br />
                  <input
                    v-model="country"
                    class="input-style w-244"
                    type="text"
                    placeholder="Country"
                  />
                  <br />
                  <br />
                </div>
              </li>
              <div
                class="res-hide-big res-remove-m-set mb-5 res-mb-4"
                style="margin-left: 64px"
              >
                <input
                  v-model="city"
                  class="input-style w-244"
                  type="text"
                  placeholder="City"
                />
                <input
                  v-model="postCode"
                  class="input-style ml-3 res-remove-ml w-244"
                  type="text"
                  placeholder="Postcode"
                />
                <input
                  v-model="county"
                  class="input-style ml-3 res-remove-ml w-244"
                  type="text"
                  placeholder="County"
                />
                <br />
                <br />
                <input
                  v-model="country"
                  class="input-style w-244"
                  type="text"
                  placeholder="Country"
                />
                <br />
                <br />
              </div>
              <li class="d-flex mb-5 res-mb-4">
                <img
                  style="margin-right: 3%"
                  class="bedroomAdvance w-30"
                  :src="require('../assets/images/phone.webp')"
                />
                <p>
                  <input
                    v-model="phnumber"
                    class="input-style w-244"
                    type="text"
                    placeholder="+447438075831"
                  />
                </p>
              </li>
              <li>
                <button
                  @click="updateProfile"
                  data-v-172e701b=""
                  class="btn btn-yellow bset res-button-how res-ml-37"
                  style="color: white"
                >
                  Save Changes
                </button>
              </li>
              <li class="mt-5 d-flex mb-0">
                <img
                  style="margin-right: 3%"
                  class="bedroomAdvance w-30"
                  :src="require('../assets/images/coupon.webp')"
                />
                <p>
                  <input
                    v-model="ref_link"
                    class="input-style w-370"
                    type="text"
                    placeholder="XTWWRT67"
                  />
                  <br />
                </p>
                <button
                  @click="copy"
                  data-v-172e701b=""
                  class="btn btn-yellow bset res-button-how res-dd-none"
                  style="
                    color: white;
                    margin-left: 34px;
                    position: absolute;
                    left: 42%;
                    top: 77%;
                  "
                >
                  Copy
                </button>
              </li>
              <div
                class="res-margin-setting"
                style="margin-left: 64px; margin-top: -29px"
              >
                <br />
                <p class="w-full" style="width: 40%">
                  This is your personal referral code. You can share this with
                  your friends and family and start earning points which you can
                  redeem when you post your next property for sale.
                </p>
              </div>
              <li>
                <button
                  @click="$refs['delete-modal'].show()"
                  class="btn btn-yellow bset res-button-how res-ml-37"
                  style="color: white"
                >
                  Delete Account
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <b-modal centered ref="delete-modal" hide-header>
      <center>
        <p class="my-4">Are you sure you want to delete your account?</p>
      </center>
      <template #modal-footer>
        <b-button
          @click="deleteAccount"
          variant="primary"
          size="md"
          class="listing-button-color btn btn-primary"
        >
          Delete
        </b-button>
        <b-button @click="$refs['delete-modal'].hide()"> Cancel </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { capitalize } from "../utils";

export default {
  data() {
    return {
      username: "",
      address: "",
      addressTwo: "",
      ref_link: "",
      profilePic: null,
      name: "",
      phnumber: "",
      houseNo: "",
      street: "",
      town: "",
      city: "",
      country: "",
      county: "",
      postCode: "",
      origin: "",
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth",
      buyerSeller: "getBuyerSeller",
      referralCount: "getReferralCount",
    }),
  },
  watch: {
    buyerSeller() {
      this.syncData();
    },
    authUser() {
      this.setReferralLink();
    },
  },
  methods: {
    deleteAccount() {
      this.$store.commit("setShowLoader",true);
      this.$store.dispatch("deleteAccount").then((res) => {
        this.$refs['delete-modal'].hide()
        this.$store.commit("setShowLoader",false);
        if (res == true) {
          this.$store.commit("setMessage", "Account deletion successful!");
          localStorage.removeItem("buyer_access_token");
          this.$store.commit("setDefaultsAuth");
          this.$store.commit("setDefaultsBlog");
          this.$store.commit("setDefaultsConfigurations");
          this.$store.commit("setDefaultsCoupon");
          this.$store.commit("setDefaultsMessage");
          this.$store.commit("setDefaultsMiscellaneous");
          this.$store.commit("setDefaultsProperty");
          this.$store.commit("setDefaultsReferral");
          this.$store.commit("setDefaultsReport");

          this.$router.push("/login");
        } else {
          this.$store.commit("setMessage", "Account deletion unsuccessful!");
        }
      });
    },
    copy() {
      this.$copyText(this.ref_link).then(() => {
        this.$store.commit("setMessage", "Link Copied");
      });
    },
    getAddress() {
      if (this.houseNo) {
        let address = "";
        let keys = ["houseNo", "county", "city", "country"];
        for (let i = 0; i < keys.length; i++) {
          address = `${_.defaultTo(this.houseNo, "")}${
            this.county ? "," : ""
          } ${_.defaultTo(this.county, "")}${
            this.city ? "," : ""
          } ${_.defaultTo(this.city, "")}${
            this.country ? "," : ""
          } ${_.defaultTo(this.country, "")}`.trim();
        }
        return address;
      } else return "No Address Added";
    },
    updateUserName() {
      this.$store
        .dispatch("updateUserName", {
          user_name: this.username,
        })
        .then((res) => {
          if (res == true) {
            this.$store.dispatch("getUser");
            this.$store.commit("setMessage", "Username Updated Successfully!");
          } else
            this.$store.commit("setMessage", "Kindly choose a unique username");
        });
    },
    updateProfile() {
      if (this.username !== this.authUser.user_name) this.updateUserName();
      this.$store.commit("setShowLoader", true);
      const data = {
        name: this.name,
        mobileNo: this.phnumber,
        houseNo: this.houseNo,
        street: this.street,
        town: this.town,
        city: this.city,
        country: this.country,
        county: this.county,
        postCode: this.postCode,
        address: this.address,
        addressTwo: this.addressTwo,
        profilePic: [this.profilePic],
      };
      this.$store.dispatch("updateProfile", data).then((res) => {
        this.$store.commit("setShowLoader", false);
        if (res == true) {
          this.$store
            .dispatch("getBuyerSeller", this.authUser.buyer_seller_id)
            .then((res) => {
              if (res.success) {
                this.syncData();
              } else alert("error in fetching buyer_seller");
            });
        } else
          this.$store.commit("Miscellaneous/setMessage", "Updation Failed!");
      });
    },
    addProfilePic(event) {
      this.$store.commit("setShowLoader", true);
      const selectedImage = event.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$store
          .dispatch("postImage", { image: e.target.result })
          .then((res) => {
            this.$store.commit("setShowLoader", false);
            this.profilePic = `${res.data}`;
          });
      };
      reader.readAsDataURL(selectedImage);
    },
    setReferralLink() {
      this.origin = window.location.origin;
      this.ref_link = `${this.origin}/referral?ref_code=${this.authUser.referral_code}`;
    },
    toCap(str) {
      return str ? capitalize(str) : "";
    },
    syncData() {
      this.username = this.authUser.user_name;
      this.name = this.buyerSeller.name;
      this.phnumber = this.buyerSeller.mobileNo;
      this.houseNo = this.buyerSeller.houseNo;
      this.street = this.buyerSeller.street;
      this.town = this.buyerSeller.town;
      this.city = this.buyerSeller.city;
      this.country = this.buyerSeller.country;
      this.county = this.buyerSeller.county;
      this.postCode = this.buyerSeller.postCode;
      this.address = this.buyerSeller.address;
      this.addressTwo = this.buyerSeller.addressTwo;
      this.profilePic = (() => {
        try {
          return JSON.parse(this.buyerSeller.profilePic);
        } catch (err) {
          return this.buyerSeller.profilePic;
        }
      })();
    },
  },
  async beforeMount() {
    if (!_.isEmpty(this.authUser))
      if (!_.isEmpty(this.buyerSeller)) this.syncData();
  },
  mounted() {
    this.setReferralLink();
    if (this.referralCount == 0) {
      this.$store.dispatch("referralCount").then((res) => {
        if (res == true) {
        } else alert("Count not fetched!");
      });
    }
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
@media (max-width: 700px) {
  .res-remove-mobile {
    display: none !important;
  }

  .res-mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .res-margin-setting {
    margin-left: 37px !important;
    margin-top: -29px !important;
  }
  .w-370 {
    width: 224px !important;
  }

  .w-full {
    width: 100% !important;
  }

  .res-dd-none {
    display: none !important;
  }
  .w-244 {
    width: 224px !important;
  }
  .dflexx {
    display: flex;
    flex-wrap: nowrap !important;
  }
  .res-remove-ml {
    margin-left: 0px !important;
  }

  .res-remove-m-set {
    margin-left: 37px !important;
    margin-top: -11px !important;
  }

  .res-ml-37 {
    margin-left: 37px !important;
  }
}

@media (min-width: 710px) and (max-width: 3000px) {
  .res-hide-big {
    display: none !important;
  }
}

.fa-phonee:after {
  content: url("../assets/images/Phonee.webp");
  width: 64px;
  height: 64px;
  display: inline-block;
}

.dflexx {
  display: flex;
  flex-wrap: wrap;
}
.w-30 {
  width: 30px;
  height: 30px;
}
.input-ref-link {
  width: 40%;
  outline: none;
}
.input-style {
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
}
.fa-priles:after {
  content: url("../assets/images/profile1.webp");
  width: 64px;
  height: 64px;
  display: inline-block;
}
.fa-fullname:after {
  content: url("../assets/images/Picture2.webp");
  width: 64px;
  height: 64px;
  display: inline-block;
}
.fa-locationss:after {
  content: url("../assets/images/Picture3.webp");
  width: 64px;
  height: 64px;
  display: inline-block;
}
.fa-prilesss:after {
  content: url("../assets/images/Picture4.webp");
  width: 64px;
  height: 64px;
  display: inline-block;
}
</style>